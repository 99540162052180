<template>
  <div>
    <div class="ovy-a">
      <!-- 列表 -->
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="编号"
              align="center"
              type="index"
              :index="indexMethod"
              fixed
            />
            <el-table-column
              label="姓名"
              align="left"
              prop="userName"
              show-overflow-tooltip
            />
            <el-table-column
              label="所属培训机构"
              align="left"
              prop="evaluateCompName"
              show-overflow-tooltip
              minWidth="220"
              fixed
            />
            <el-table-column
              label="报名时间"
              align="left"
              width="150"
              prop="createTime"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.createTime || "--"
              }}</template>
            </el-table-column>

            <el-table-column
              label="报名方式"
              align="left"
              prop="createTime"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.applyType == 0
                  ? "机构导入"
                  : (scope.row.applyType == 1 ? "个人报名" : "安知导入") || "--"
              }}</template>
            </el-table-column>

            <el-table-column label="操作" align="center" fixed="right">
              <div slot-scope="scope" class="flexcc">
                <el-button
                  type="text"
                  style="padding: 0px 5px"
                  size="mini"
                  @click="onShow(scope.row)"
                  >查看报名信息</el-button
                >
              </div>
            </el-table-column>
            <!-- <Empty slot="empty" /> -->
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
    <!-- <span
      slot="footer"
      class="dialog-footer"
      style="display: flex; justify-content: center"
    >
      <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
      <el-button class="bgc-bv" @click="getSure('Form')">保存</el-button>
    </span> -->

    <!-- 选择工种弹窗 -->
    <!-- <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      width="40%"
      append-to-body
    >
      <div>个人信息</div>
      <div class="container">
        <div>所属培训机构：{{ cities.evaluateCompName }}</div>
        <div>姓名：{{ cities.userName }}</div>
        <div>证件类型：{{ cities.idType }}</div>
        <div>证件号：{{ cities.idcard }}</div>
        <div>性别：{{ cities.sex == 1 ? "男" : "女" }}</div>
        <div>出生日期：{{ cities.birthday }}</div>
        <div>文化程度：{{ cities.education }}</div>
      </div>
    </el-dialog> -->

    <el-dialog
      title="预览信息"
      append-to-body
      :visible.sync="dialogShow"
      width="50%"
    >
      <div class="ovy-a" style="height: 600px">
        <el-form
          disabled
          :model="evaluateUserForm"
          :rules="evaluateUserRules"
          ref="evaluateUserForm"
          label-width="120px"
        >
          <div>
            <h3 style="padding-bottom: 20px">个人信息</h3>
            <el-form-item label="所属培训机构：" prop="evaluateCompId">
              <el-select
                size="small"
                v-model="evaluateUserForm.evaluateCompId"
                allow-create
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                readonly
                placeholder="请输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名：" prop="userName">
              <el-input
                v-model="evaluateUserForm.userName"
                readonly
                placeholder="请输入姓名"
                maxlength="100"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件类型：" prop="idType">
              <el-select
                v-model="evaluateUserForm.idType"
                readonly
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in evaluateIdTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号：" prop="idcard">
              <el-input
                v-model="evaluateUserForm.idcard"
                readonly
                placeholder="请输入证件号"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-select
                v-model="evaluateUserForm.sex"
                readonly
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出生日期：" prop="birthday">
              <el-date-picker
                readonly
                size="small"
                v-model="evaluateUserForm.birthday"
                type="date"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="文化程度：" prop="evaluateEducationLevel">
              <el-select
                readonly
                v-model="evaluateUserForm.evaluateEducationLevel"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码：" prop="mobile">
              <el-input
                readonly
                v-model="evaluateUserForm.mobile"
                placeholder="请输入手机号码"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="考生来源：" prop="examineSource">
              <el-select
                readonly
                v-model="evaluateUserForm.examineSource"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in evaluateExamUserSourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证书领取：" prop="receiveType">
              <el-select
                readonly
                v-model="evaluateUserForm.receiveType"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in evaluateCertPaymentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="快递地址：" prop="expressAddress">
              <el-input
                readonly
                v-model="evaluateUserForm.expressAddress"
                placeholder="请输入快递地址"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="申报条件：" prop="declareCondition">
              <el-input
                readonly
                v-model="evaluateUserForm.declareCondition"
                placeholder="请输入申报条件"
                maxlength="100"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="省份：" prop="provinceId">
              <el-select
                readonly
                v-model="evaluateUserForm.provinceId"
                placeholder="请选择"
                clearable
                filterable
                size="small"
                @change="provinceChange"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.areaId"
                  :label="item.areaName"
                  :value="item.areaId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市：" prop="cityId">
              <el-select
                readonly
                v-model="evaluateUserForm.cityId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.areaId"
                  :label="item.areaName"
                  :value="item.areaId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在单位：" prop="compName">
              <el-input
                readonly
                v-model="evaluateUserForm.compName"
                placeholder="请输入所在单位"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="职业名称：" prop="workName">
              <el-input
                readonly
                v-model="evaluateUserForm.workName"
                placeholder="请输入职业名称"
                maxlength="100"
                size="small"
              ></el-input>
            </el-form-item>
            <!-- </div>
          <h3
            style="padding-bottom: 20px"
          >
            个人信息
          </h3>
          <div> -->
            <el-form-item label="参加工作时间：" prop="timeToWork">
              <el-date-picker
                readonly
                size="small"
                v-model="evaluateUserForm.timeToWork"
                type="date"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="从事专业：" prop="profession">
              <el-input
                readonly
                v-model="evaluateUserForm.profession"
                placeholder="请输入从事专业"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="专业年限：" prop="professionLife">
              <el-input
                readonly
                v-model="evaluateUserForm.professionLife"
                placeholder="请输入专业年限"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="民族：" prop="nation">
              <el-select
                readonly
                v-model="evaluateUserForm.nation"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in nationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="政治面貌：" prop="politicsStatus">
              <el-select
                readonly
                v-model="evaluateUserForm.politicsStatus"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in politiceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="简要经历：" prop="experience">
              <el-input
                readonly
                v-model="evaluateUserForm.experience"
                placeholder="请输入简要经历"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="通讯地址：" prop="address">
              <el-input
                readonly
                v-model="evaluateUserForm.address"
                placeholder="请输入通讯地址"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
              <el-input
                readonly
                v-model="evaluateUserForm.email"
                placeholder="请输入所在单位"
                size="small"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <h3 style="padding-bottom: 20px">考试信息</h3>
        <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
          <el-form-item label="鉴定考试工种：" prop="examOccupationId">
            <el-select
              v-model="ruleForm.examOccupationId"
              placeholder="请选择"
              clearable
              filterable
              size="small"
              disabled
            >
              <el-option
                v-for="item in occupation"
                :key="item.occupationId"
                :label="item.occupationName"
                :value="item.occupationId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="考试级别：" prop="examLevel">
            <el-select
              v-model="ruleForm.examLevel"
              placeholder="请选择"
              size="small"
              disabled
            >
              <el-option
                v-for="item in ExaminationLevelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="理论考试时间：" prop="theoryTestTime">
            <el-date-picker
              disabled
              size="small"
              v-model="ruleForm.theoryTestTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="理论考试开始日期"
              end-placeholder="理论考试结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="理论考试地点：" prop="examTheoryPlace">
            <el-input
              disabled
              v-model="ruleForm.examTheoryPlace"
              placeholder="请输入理论考试地点"
              maxlength="100"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="实操考试时间：" prop="practiceTestTime">
            <el-date-picker
              disabled
              size="small"
              v-model="ruleForm.practiceTestTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="实操考试开始日期"
              end-placeholder="实操考试结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实操考试地点：" prop="examPracticePlace">
            <el-input
              disabled
              v-model="ruleForm.examPracticePlace"
              placeholder="请输入实操考试地点"
              maxlength="100"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="综合评审时间：" prop="comprehensiveReviewTime">
            <el-date-picker
              disabled
              size="small"
              v-model="ruleForm.comprehensiveReviewTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="综合评审开始日期"
              end-placeholder="综合评审结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="综合评审地点：" prop="examReviewPlace">
            <el-input
              v-model="ruleForm.examReviewPlace"
              placeholder="请输入综合评审地点"
              maxlength="100"
              disabled
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    PageNum,
  },
  mixins: [List],
  data() {
    var validateCompPhone = (rule, value = "", callback) => {
      if (this.selectCities.length == 0) {
        callback(new Error("请选择工种"));
      } else {
        callback();
      }
    };
    return {
      occupatioSize: 16, // 工种列表每页条数
      occupatioTotal: 0, // 工种列表总条数
      // 选择机构
      CompanyList: [],
      // 多选选择框
      checkList: [],
      cities: "", // 学生信息
      selectCities: [],
      // 表单数据
      Form: {
        compNo: "", // 社会信用代码
        compId: "", // 机构id
        areaId: "", // 地区
        compAddress: "", // 地址
        compUser: "", // 负责人
        compPhone: "", // 联系电话
        otherContacts: "", // 其他联系人
        otherContactsPhone: "", // 其他联系人电话
        maturityDate: "", // 到期日期
        occupation: "", // 可鉴定工种
        effectiveDate: "", // 成立日期
        remark: "", // 备注
      },
      areaId: "",
      areatreeList: [], // 行政区划
      // 弹窗设置
      dialogShow: false,
      dialogTitle: "",
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 验证
      rules: {
        compNo: [{ required: true, message: "请选择机构", trigger: "blur" }],
        compId: [{ required: true, message: "请选择机构", trigger: "blur" }],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        compAddress: [
          { required: true, message: "请输入机构地址", trigger: "change" },
        ],
        compUser: [
          { required: true, message: "请输入负责人", trigger: "change" },
        ],
        compPhone: [
          {
            required: true,
            message: "请输入正确的联系电话",
            trigger: "change",
          },
        ],
        occupation: {
          required: true,
          message: "请选择工种",
          trigger: "change",
          validator: validateCompPhone,
        },
      },
      //个人信息必填部分
      evaluateUserForm: {
        evaluateCompId: "",
        userName: "",
        birthday: "",
        idcard: "",
        sex: "",
        mobile: "",
        nation: "",
        address: "",
        email: "",
        idType: "",
        idcard: "",
        examineSource: "",
        evaluateEducationLevel: "",
        provinceId: "",
        cityId: "",
        compName: "",
        profession: "",
        professionLife: "",
        politicsStatus: "",
        experience: "",
        receiveType: "",
        expressAddress: "",
        declareCondition: "",
      },
      //基本设置
      ruleForm: {
        examOccupationId: "", //考试工种
        examLevel: "", //考试工种
        theoryTestTime: "", //理论考试时间
        examTheoryPlace: "", //理论考试地点
        practiceTestTime: "", //实操考试时间
        examPracticePlace: "", //实操考试地点
        comprehensiveReviewTime: "", //综合评审时间
        examReviewPlace: "", //综合评审地点
        examEndTime: "", //报名截止时间
        examPeople: "", //考试人数
      },
      provinceList: [], //省份数据
      cityList: [], //城市数据
      //考试工种
      occupation: [],
      //考试级别
      ExaminationLevelList: [
        {
          value: "一级",
          label: "一级",
        },
        {
          value: "二级",
          label: "二级",
        },
        {
          value: "三级",
          label: "三级",
        },
        {
          value: "四级",
          label: "四级",
        },
        {
          value: "五级",
          label: "五级",
        },
      ],
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getData();
    this.getareatree();
    this.getProvince();
    this.getCrowdtypeList();
  },
  methods: {
    //码值获取信息
    getCrowdtypeList() {
      const evaluateIdTypeList = this.$setDictionary(
        "EVALUATE_ID_TYPE",
        "list"
      );
      const evaluateEducationList = this.$setDictionary(
        "EVALUATE_EDUCATION",
        "list"
      );
      //考生来源
      const evaluateExamUserSourceList = this.$setDictionary(
        "EVALUATE_EXAM_USER_SOURCE",
        "list"
      );
      //证书领取
      const evaluateCertPaymentList = this.$setDictionary(
        "EVALUATE_CERT_PAYMENT",
        "list"
      );
      //性别
      const sex = this.$setDictionary("SEX", "list");
      //民族
      const nation = this.$setDictionary("NATION", "list");
      //政治面貌
      const politice = this.$setDictionary("EVALUATE_POLITICS_STATUS", "list");
      const list = [],
        educationList = [],
        ExamUserSourceList = [],
        CertPaymentList = [],
        sexList = [],
        nationList = [],
        politiceList = [];
      for (const key in evaluateIdTypeList) {
        list.push({
          value: key,
          label: evaluateIdTypeList[key],
        });
      }
      for (const key in evaluateEducationList) {
        educationList.push({
          value: key,
          label: evaluateEducationList[key],
        });
      }
      for (const key in evaluateExamUserSourceList) {
        ExamUserSourceList.push({
          value: key,
          label: evaluateExamUserSourceList[key],
        });
      }
      for (const key in evaluateCertPaymentList) {
        CertPaymentList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
      for (const key in sex) {
        sexList.push({
          value: key,
          label: sex[key],
        });
      }
      for (const key in nation) {
        nationList.push({
          value: key,
          label: nation[key],
        });
      }
      for (const key in politice) {
        politiceList.push({
          value: key,
          label: politice[key],
        });
      }
      this.evaluateIdTypeList = list;
      this.educationList = educationList;
      this.evaluateExamUserSourceList = ExamUserSourceList;
      this.evaluateCertPaymentList = CertPaymentList;
      this.sexList = sexList;
      this.nationList = nationList;
      this.politiceList = politiceList;
    },
    getUserInfo(evaluateExamUserId) {
      this.$post("/evaluate/user/getUserInfo", { evaluateExamUserId }).then(
        (ret) => {
          console.log(ret);
          console.log(
            "ret.data.evaluateUser.provinceId",
            ret.data.evaluateUser.provinceId
          );
          this.getCity(ret.data.evaluateUser.provinceId);
          this.evaluateUserForm = {
            ...ret.data.user,
            ...ret.data.evaluateUser,
            ...ret.data.evaluateExamUser,
          };
          console.log("evaluateUserForm", this.evaluateUserForm);
          this.evaluateUserForm.evaluateCompId =
            this.evaluateUserForm.evaluateCompName;
        }
      );
    },
    getProvince() {
      this.$post("/evaluate/user/selectProvinceList").then((ret) => {
        this.provinceList = ret.data || [];
      });
    },
    getCity(areaId) {
      this.$post("/evaluate/user/selectCityList", { areaId }).then((ret) => {
        this.cityList = ret.data || [];
      });
    },
    /* 鉴定考试工种 */
    getOccupation() {
      this.$post("/evaluate/exam/occupation")
        .then((ret) => {
          this.occupation = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    /* 基本信息回显数据 */
    getInformation(examId) {
      this.$post("/evaluate/exam/info", { examId })
        .then((ret) => {
          let retData = ret.data;
          this.ruleForm = {
            ...retData,
            examOccupationId: retData.examOccupationId,
            examLevel: retData.examLevel,
            theoryTestTime: [
              retData.examTheoryStart.replaceAll("/", "-"),
              retData.examTheoryEnd.replaceAll("/", "-"),
            ],
            practiceTestTime: [
              retData.examPracticeStart.replaceAll("/", "-"),
              retData.examPracticeEnd.replaceAll("/", "-"),
            ],
            // examEndTime:retData.examEndTime.replaceAll('/','-')
          };
          //  if(retData.examReviewStart) {
          //     this.ruleForm.comprehensiveReviewTime = [retData.examReviewStart.replaceAll('/','-'),retData.examReviewEnd.replaceAll('/','-')]
          //  }
          console.log(this.ruleForm);
        })
        .catch((err) => {
          return;
        });
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        examId: this.id,
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      this.doFetch({
        url: "/evaluate/exam/operate/student",
        params,
        pageNum,
      });
      
    },

    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },

    // 显示弹窗
    onShow(row) {
      console.log("id", row.evaluateExamUserId);
      this.dialogShow = !this.dialogShow;
      this.getUserInfo(row.evaluateExamUserId);
      this.getOccupation();
      this.getInformation(this.id);
    },
    // // 获取学习信息
    // getOccupation(id) {
    //   ;
    //   this.$post("/evaluate/user/getUserInfo", {
    //     evaluateExamUserId: id,
    //   })
    //     .then((res) => {
    //       this.occupatioTotal = res.data.total;

    //       this.cities = {
    //         ...res.data.user,
    //         ...res.data.evaluateUser,
    //         ...res.data.evaluateExamUser,
    //       };
    //       console.log("获取学习信息", this.cities);
    //       // 证件类型
    //       const evaluateIdTypeList = this.$setDictionary(
    //         "EVALUATE_ID_TYPE",
    //         "list"
    //       );

    //       const educationLevel = this.$setDictionary(
    //         "EVALUATE_EDUCATION",
    //         "list"
    //       );

    //       this.cities.idType = evaluateIdTypeList[this.cities.idType];
    //     })
    //     .catch(() => {
    //       return;
    //     });
    // },

    // 关闭新增鉴定机构
    doClose() {
      this.$emit("close");
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
          console.log(this.areatreeList);
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>

<style lang="less" scoped>
i:hover {
  cursor: pointer;
}
.title {
  text-align: center;
  margin-bottom: 15px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-items: self-start;
  
}
.container {
}
</style>